import { Box, Divider, Grid, Typography } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import { COLORS } from '../../constants/colors'
import { QueryOptions } from '../../helpers/query.options'
import { getCartTotal, getCartTotalWithStock, loadCartItems } from '../../helpers/utilities'
import { PaymentMethod } from '../../models/payment/PaymentMethod'
import { ShippingMethod } from '../../models/shipping/ShippingMethod'
import { Title } from '../Typography/Title'
import { shippingMethodService } from '../../models/shipping/service'
import { paymentMethodService } from '../../models/payment/service'
import { CouponComponent } from './CouponComponent'
import { Animated } from "react-animated-css";
import { ChipComponent } from '../Chip/ChipComponent'
import { BlackButton } from '../Button/BlackButton'
import { LoyaltyCardComponent } from './LoyaltyCardComponent'
import { Settings } from '../../models/settings/settings'
import { settingsService } from '../../models/settings/service'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

export const CheckoutThirdStepComponent = (props) => {
    const { app, language, translations, address, setStep,
        onReloadCart,
        setSnackBar,
        onSetShippingMethod,
        onSetPaymentMethod,
        onSetDiscount,
        setCart, coupon,
        loyaltyCard,
        setLoyaltyCard,
        setCoupon } = props
    const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([])
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
    const [settings, setSettings] = useState<Settings>()
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>()
    const [discount, setDiscount] = useState(0)
    const [shippingMethod, setShippingMethod] = useState<ShippingMethod>()
    const [loaded, setLoaded] = useState(false)
    const [animateShippingError, setAnimateShippingError] = useState(false)
    const [animatePaymentError, setAnimatePaymentError] = useState(false)

    useEffect(() => {
        loadCartItems(setCart, language)
    }, [])

    useEffect(() => {
        settingsService.readWithNoId(new QueryOptions()).then((resp) => {
            setSettings(resp)
        }).catch(() => { })
    }, [])

    useEffect(() => {
        if (app.cart?.length) {
            if (app.cart.length === 1) {
                const [card] = app.cart;
                loadShippingMethods(card.sku === 'e_gift_card_001' ? "eCard" : "normal", address.city.uid)
            } else {
                loadShippingMethods("normal", address.city.uid)
            }
            setLoaded(true)
        }

    }, [app.cart, coupon])

    useEffect(() => {
        if (shippingMethods?.length === 1) {
            onSetShippingMethod(shippingMethod)
        } else {
            onSetShippingMethod(null)
        }

    }, [shippingMethods])

    useEffect(() => {

        if (coupon) {
            const total = getCartTotalWithStock(app.cart) + getShippingPrice(shippingMethod, settings, app.cart, coupon) - discount;
            if (paymentMethod && paymentMethod.minValue > total) {
                setPaymentMethod(null)
            }

        }
        if (shippingMethod?.id) {
            loadPaymentMethods(shippingMethod.id)
        }
        return () => {
        }
    }, [shippingMethod, coupon])


    useEffect(() => {
        if (onSetDiscount) {
            onSetDiscount(discount)
        }
    }, [discount])

    useEffect(() => {
        if (shippingMethods.length === 1) {
            const [method] = shippingMethods.filter(method => method.id === '6443e57bb96dfcba77e52288')
            if (method.id) {
                setShippingMethod(method)
            }
        }
    }, [shippingMethods])

    useEffect(() => {
        const getToPay = () => {
            if (coupon.campaign.isGiftCard) {
                return coupon.campaign.value
            }
            return coupon.campaign.value <= getCartTotal(app.
                cart) ? coupon.campaign.value : 0
        }
        if (coupon) {
            console.log(coupon)
            console.log(getCartTotal(app.
                cart))
            setDiscount(coupon.campaign.type === 'percentage' ? coupon.products.reduce((total, item) => {
                return total += item.discount * item.quantity;
            }, 0) : getToPay())
        } else if (loyaltyCard) {
            setDiscount(loyaltyCard.products.reduce((total, item) => {
                return total += item.discount * item.quantity;
            }, 0))
        }
        else {
            setDiscount(0)
        }
    }, [loyaltyCard, coupon])



    const loadShippingMethods = (type, city) => {
        const options = new QueryOptions()
        options.lang = language
        options.city = city
        shippingMethodService.list(options).then((r: any) => {
            if (type === 'eCard') {
                setShippingMethods(r.results.filter(method => method.id === '6443e57bb96dfcba77e52288'))
            } else {
                setShippingMethods(r.results.filter(method => method.id !== '6443e57bb96dfcba77e52288'))
            }
        })
    }
    const loadPaymentMethods = (shipping: string) => {
        const options = new QueryOptions()
        options.lang = language
        options.shipping_method = shipping
        paymentMethodService.list(options).then((r: any) => {
            if (r.results?.length) {
                if (coupon?.campaign?.isGiftCard) {
                    if (coupon?.campaign?.value >= getCartTotal(app.cart)) {
                        setPaymentMethods(r.results.filter(r => r.uid === 'gift_card'))
                    } else {
                        setPaymentMethods(r.results.filter(r => r.uid === 'card_bog'))
                    }
                } else {
                    setPaymentMethods(r.results.filter(r => r.uid !== 'gift_card'))
                }
            }
        })
    }

    const getShippingPrice = (shippingMethod, settings, cartItems, coupon) => {
        var cost = 0;
        if (shippingMethod) {
            cost = shippingMethod.price;
        }
        const total = getCartTotalWithStock(cartItems)

        if (settings && settings.freeShippingMin) {
            if (total >= Number(settings.freeShippingMin)) {
                cost = 0
            }
        }
        if (coupon?.campaign?.freeShipping) {
            cost = 0
        }
        return cost
    }

    const RenderTotal = useCallback(() => {

        return <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 17, }}>
            {Number(getCartTotalWithStock(app.cart).toFixed(2))} ₾
        </Typography>
    }, [app.cart, shippingMethod])

    const RenderDiscount = useCallback(() => {
        return <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 17, }}>
            {discount.toFixed(2)} ₾
        </Typography>
    }, [discount])

    const RenderShippingCost = useCallback(() => {
        var cost = shippingMethod.price
        if (settings && settings.freeShippingMin) {

            if (getCartTotalWithStock(app.cart) > Number(settings.freeShippingMin)) {
                cost = 0;
            }
        }
        if (coupon?.campaign?.freeShipping) {
            cost = 0
        }
        return <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 17, }}>
            {cost.toFixed(2)} ₾
        </Typography>
    }, [shippingMethod, app.cart, settings, coupon])

    const RenderYouPay = useCallback(() => {
        return <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 17, }}>
            {Number(getCartTotalWithStock(app.cart) + getShippingPrice(shippingMethod, settings, app.cart, coupon) - discount).toFixed(2)} ₾
        </Typography>
    }, [discount, shippingMethod, settings, coupon])


    return <Animated animationIn="slideInLeft" animationOut="fadeOutRight"
        animationInDuration={500}
        isVisible={loaded} >
        <Grid container style={{ padding: window.innerWidth > 1200 ? 30 : 0 }}>
            <Grid item lg={9} style={{ width: '100%', flexDirection: 'column', display: 'flex' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingLeft: 4, flexDirection: 'column' }}>
                    <Animated animationIn="shake" animationOut="fadeIn"
                        animationInDuration={1200}
                        isVisible={animateShippingError}>

                        <Title
                            uppercase={true}
                            fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                            color={'#333'}
                            fontSize={19}
                            fontWeight={'bold'}
                            text={translations?.shipping} />
                    </Animated>
                    <Title
                        uppercase={true}
                        fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                        color={'#444'}
                        fontSize={15}
                        fontWeight={'500'}
                        text={`${address?.street}, ${address?.city?.name}`} />
                </Box>
                <Box sx={{ padding: 2, paddingTop: 1, display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                    {shippingMethods.map((item, index) => {
                        return <ChipComponent
                            {...props}
                            id={""}
                            key={index}
                            onClick={() => {
                                setShippingMethod(item)
                                onSetShippingMethod(item)
                                setAnimateShippingError(false)
                            }}
                            title={item.name}
                            selected={shippingMethod?.id === item.id} />
                    })}
                </Box>
                {shippingMethod && <Title
                    uppercase={false}
                    style={{ marginTop: -10, padding: 10, marginLeft: 20, width: '60%' }}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={'#444'}
                    fontSize={14}
                    fontWeight={'400'}
                    text={`${shippingMethod.description} - ${shippingMethod.price.toFixed(0)} ₾`} />}
                {paymentMethods?.length > 0 && <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingLeft: 4 }}>
                    <Animated animationIn="shake" animationOut="fadeIn"
                        animationInDuration={1200}
                        isVisible={animatePaymentError}>
                        <Title
                            uppercase={true}
                            fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                            color={'#333'}
                            fontSize={19}
                            fontWeight={'bold'}
                            text={translations?.payment} />
                    </Animated>

                </Box>}
                <Box sx={{ padding: 2, paddingTop: 1, display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
                    {paymentMethods.map((item, index) => {
                        return <ChipComponent
                            {...props}
                            key={index}
                            onClick={() => {
                                setPaymentMethod(item)
                                onSetPaymentMethod(item)
                                setAnimatePaymentError(false)
                            }}
                            title={item.name}
                            logo={true}
                            item={item}
                            selected={paymentMethod?.id === item.id} />
                    })}
                </Box>
                {shippingMethod ?
                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} >
                        {window.innerWidth < 1200 ? <Divider component="div" flexItem sx={{ marginBottom: 2, marginLeft: 5, marginRight: 5 }} /> : <></>}
                        <Box sx={{ paddingLeft: 3.1, paddingRight: 3.1, paddingTop: 1, display: 'flex', alignItems: 'flex-start' }}>
                            <LoyaltyCardComponent
                                {...props}
                                onReloadCart={() => {
                                    onReloadCart()
                                }}
                                onError={(value) => {
                                    var text = translations.owner_id_not_found
                                    if (value) {
                                        text = translations["discount_not_compatible"]
                                    }
                                    setSnackBar({ ...app.snackBar, open: true, text, bColor: 'rgb(234, 51, 35)' })
                                }}
                                setLoyaltyCard={setLoyaltyCard}
                                loyaltyCard={loyaltyCard}
                            />
                        </Box>

                        {window.innerWidth < 1200 ? <Divider component="div" flexItem sx={{ marginTop: 3, marginLeft: 5, marginRight: 5 }} /> : <></>}
                        <Box sx={{ paddingLeft: 3.1, paddingRight: 3.1, paddingTop: 3, display: 'flex', alignItems: 'flex-start' }}>
                            <CouponComponent
                                {...props}
                                onReloadCart={() => {
                                    onReloadCart()
                                }}
                                onError={() => {
                                    setSnackBar({ ...app.snackBar, open: true, text: translations.coupon_not_valid, bColor: 'rgb(234, 51, 35)' })
                                }}
                                onAddMore={(diff) => {
                                    setSnackBar({ ...app.snackBar, open: true, text: `${translations.add_more_you_loose} ${diff} ₾`, bColor: 'rgb(234, 51, 35)' })

                                }}
                                coupon={coupon}
                                setCoupon={(coupon) => {
                                    setCoupon(coupon)
                                    setPaymentMethod(null)
                                }} />
                        </Box>
                        {window.innerWidth < 1200 ? <Divider component="div" flexItem sx={{ marginTop: 2, marginLeft: 5, marginRight: 5 }} /> : <></>}
                    </Animated>
                    : <></>}
            </Grid>

            <Grid item lg={3} xs={12} style={{ padding: 10, paddingTop: 0, paddingBottom: 80, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{

                    width: window.innerWidth > 1200 ? '100%' : '90%',
                    borderRadius: 2,
                    flex: 1,
                    marginTop: 2,
                    paddingTop: 1.2,
                    boxShadow: '0px 0px 4px rgba(0,0,0,.5)',
                }}>
                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 20, paddingRight: 20, paddingBottom: 8 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: 'bold', fontSize: 17 }}>
                                {translations?.sum}
                            </Typography>

                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <RenderTotal />
                        </div>
                    </div>
                    <Divider component="div" flexItem sx={{ marginBottom: 1 }} />
                    {discount > 0 && <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 20, paddingRight: 20 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: 'bold', fontSize: 17 }}>
                                {translations?.discount}
                            </Typography>

                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <RenderDiscount />
                        </div>
                    </div>}
                    {loyaltyCard?.discount > 0 && <> <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 20, paddingRight: 20, paddingBottom: 8 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '600', fontSize: 14, textAlign: 'initial' }}>
                                {translations?.loyalty_card_discount}
                            </Typography>

                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>

                            <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 14 }}>
                                {`-${loyaltyCard.discount}%`}
                            </Typography>
                        </div>
                    </div><Divider component="div" flexItem sx={{ marginBottom: 1 }} /></>}
                    {coupon?.name && <><div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 20, paddingRight: 20, paddingBottom: 8 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '600', fontSize: 14, textAlign: 'initial' }}>
                                {translations?.coupon_discount}
                            </Typography>

                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography component="h4" variant="subtitle2" style={{ fontWeight: 'bold', fontSize: 14 }}>
                                {coupon.campaign.type == "absolute" ?
                                    ` -${coupon.campaign.value}₾`
                                    :
                                    `-${coupon.campaign.value}%`}
                            </Typography>
                        </div>
                    </div><Divider component="div" flexItem sx={{ marginBottom: 1 }} /></>}
                    {shippingMethod && <> <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 20, paddingRight: 20, paddingBottom: 8 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: 'bold', fontSize: 17 }}>
                                {translations?.shipping_cost}
                            </Typography>

                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <RenderShippingCost />
                        </div>
                    </div> <Divider component="div" flexItem sx={{ marginBottom: 1 }} /></>}


                    <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', marginTop: 20, padding: 20 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: '500', fontSize: 15 }}>
                                {translations?.you_have_items_in_your_cart ? translations?.you_have_items_in_your_cart.replace("%s", app.cart?.length) : ''}
                            </Typography>
                        </div>
                    </div>
                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <Typography
                                component="h4"
                                variant="subtitle2"
                                className={'uppercase'}
                                style={{ fontWeight: 'bold', fontSize: 17, textTransform: 'uppercase' }}>
                                {translations?.you_pay}
                            </Typography>
                        </div>
                        <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                            <RenderYouPay />
                        </div>
                    </div>
                </Box>
                <div style={{ flexDirection: 'row', justifyContent: 'space-around', display: 'flex', padding: window.innerWidth > 1200 ? 20 : 0, width: '90%', marginTop: 20 }}>
                    <BlackButton
                        title={translations.next}
                        onClick={() => {
                            //registerOrder() 
                            if (!shippingMethod) {
                                setAnimateShippingError(true)
                                setTimeout(() => {
                                    setAnimateShippingError(false)
                                }, 5000)
                                return
                            }
                            if (!paymentMethod) {
                                setAnimatePaymentError(true)
                                setTimeout(() => {
                                    setAnimatePaymentError(false)
                                }, 5000)
                                return
                            }
                            setStep(4)
                        }}
                    />

                </div>
                {animateShippingError && <Title
                    uppercase={true}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={COLORS.RED}
                    fontSize={14}
                    fontWeight={'400'}
                    text={`${translations?.shipping} ${translations?.is_required}`} />}
                {animatePaymentError && <Title
                    uppercase={true}
                    fontFamily={language === 'geo' ? 'FiraGo' : 'Roboto'}
                    color={COLORS.RED}
                    fontSize={14}
                    fontWeight={'400'}
                    text={`${translations?.payment} ${translations?.is_required}`} />}
            </Grid>
        </Grid >
    </Animated >
}