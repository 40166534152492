import React, { useState, useEffect } from "react";
import { register } from "../models/user/authActions";
import { connect } from 'react-redux'
import "../components/styles.scss"
import { Animated } from "react-animated-css";
import { COLORS } from "../constants/colors";
import { MainPageSlider } from "../components/Carousel/MainPageSlider";
import { BrandCarouselComponent } from "../components/Carousel/BrandCarouselComponent";
import { MainPageNewProductSectionComponent } from "../components/MainPage/MainPageNewProductSectionComponent";
import { MainPageProductSliderComponent } from "../components/MainPage/MainPagProductSliderComponent";
import { QueryOptions } from "../helpers/query.options";
import { mainPageProductSliderService } from "../models/MainPageBrandBanner/service";
import { MainPageProductSlider } from "../models/MainPageBrandBanner/MainPage";
import { ProductSectionWithBG } from "../components/MainPage/ProductSectionWithBG";
import { getWindowDimensions } from "../helpers/utilities";
import { MobileMainPageSlider } from "../components/Carousel/MobileMainPageSlider";
import { MobileBrandCarouselComponent } from "../components/Carousel/MobileBrandCarouselComponent";
import { useHistory } from "react-router";
import { MobileMainPageProductSliderComponent } from "../components/MainPage/MobileMainPagProductSliderComponent";
import { MainPageProductSliderWithBannerComponent } from "../components/MainPage/MainPageProductSliderWithBannerComponent";
import { MainPageProductBanners } from "../components/MainPage/MainPageProductBanners";
import { Box, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import HolidayPopup from "../components/Checkout/HolidayPopup";
import { DeliveryWarningComponent } from "../components/MainPage/DeliveryWarningComponent";

const MainPage = (props) => {
  const { language, translations } = props
  const [isVisible, setIsVisible] = useState(false)
  const [sliders, setSliders] = useState<MainPageProductSlider[]>([])
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const history = useHistory()
  const [loadStatus, setLoadStatus] = useState(true)
  const {width} = useWindowDimensions()
  
  const [open, setOpen] = useState(false)
  const [isWarningVisible, setIsWarningVisible] = useState(false)


  useEffect(() => {
    
    let isCancelled = false

    const loadSlider = () => {
      const options = new QueryOptions()
      options.lang = language
      mainPageProductSliderService.list(options).then((resp: any) => {
        !isCancelled && setSliders(resp.results)
      })
    }

    loadSlider()

    
    setTimeout(() => {
      !isCancelled && setIsVisible(true)
    }, 0)
    setTimeout(() => {
      !isCancelled &&  setLoadStatus(false)
    }, 2500)
    
    return () => {
      isCancelled = true
    }
  }, [language])
  
  useEffect(() => {
    const currentDate = new Date();
    const targetDate = new Date('2025-01-03');
  
    const boolean = localStorage.getItem("holiday-popper");
  
    if (currentDate < targetDate && !boolean) {
      setTimeout(() => {
        setOpen(true)
        setIsWarningVisible(true)
      }, 1000)
    } 
    else if (currentDate < targetDate && boolean === "true") {
      setTimeout(() => {
        setOpen(false);
        setIsWarningVisible(true)
      },1000)
    }
    else if (currentDate > targetDate && !boolean) {
      setOpen(false)
      setIsWarningVisible(false)
    }
    else if (currentDate > targetDate && boolean === "true") {
        setOpen(false)
        setIsWarningVisible(false)
    } 
    
  }, []);



  return <div>
    {/* 
    {loadStatus ? <div style={{ position: 'absolute', width: "100%", height: '100%', zIndex: 111111, backgroundColor: 'white', marginTop: 10 }}>
      <CircularProgress style={{ position: 'fixed', top: '50%', left: window.innerWidth > 1200 ? ' 50%' : '45%', color: "black" }} />
    </div>
      : ''} */}
    {width < 600 && <HolidayPopup translations={translations} language={language} open={open} setOpen={setOpen}/>}

   {isWarningVisible && width < 600 && <DeliveryWarningComponent setOpen={setOpen} translations={translations}/>}
    <div style={{ backgroundColor: COLORS.BEIGE, minHeight: window.innerWidth > 1200 ? '488px' : "280px" }}>
      {<div style={{ width: windowDimensions.width > 1200 ? 1200 : '100%', margin: '0px auto', padding: windowDimensions.width > 1200 ? 30 : 0 }}>
        {isVisible && <Animated animationIn="fadeInRight" animationOut="fadeOut" isVisible={isVisible}>
          <div>
            {windowDimensions.width > 1200 ? <MainPageSlider /> : <MobileMainPageSlider />}
          </div>
        </Animated>}
      </div>}
    </div>
    <div className={"container"} style={{ width: windowDimensions.width > 1200 ? 1200 : '100%', margin: '0px auto', minHeight: window.innerWidth > 1200 ? 162 : 0 }}>
      {true && <div className="info-container" >
        {isVisible && <Animated animationIn="fadeInDown" animationOut="fadeOut" isVisible={isVisible}>
          <div style={{marginBottom: 20}}>
            {windowDimensions.width > 1200 ? <BrandCarouselComponent
              onRedirect={(id) => {
                history.push(`/brand/${id}`)
              }}
            /> : <MobileBrandCarouselComponent
              onRedirect={(id) => {
                history.push(`/brand/${id}`)
              }}
            />}
          </div>
        </Animated>}
      </div>}
    </div >
    <div className={"container"} >
      {<div className="info-container" >
        {isVisible && <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isVisible}>
          <div >
            <MainPageNewProductSectionComponent
              {...props}
            />
          </div>
        </Animated>}
      </div>}
    </div >
    <div className={window.innerWidth > 1200 ? "container" : ""} style={{ minHeight: window.innerWidth > 1200 ? 1200 : 0 }}>
      <div className="info-container" style={window.innerWidth > 1200 ? { width: 1200, margin: '0px auto' } : {}}>
        {isVisible && <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isVisible}>
          <div >
            <MainPageProductSliderWithBannerComponent
              imageHeight={"40%"}
              {...props}
            />
          </div>
        </Animated>}
      </div>
    </div >
    <div className={window.innerWidth > 1200 ? "container" : ""} style={{ width: windowDimensions.width > 1200 ? 1200 : '100%', margin: '0px auto', marginTop: 0, marginBottom: width < 1200 ? '40px' : '0px' }}>
      {loadStatus ? <div className="info-container">
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
          <div >
            {window.innerWidth > 1200 ? <Skeleton width={1200} height={280} /> :
              <Skeleton width={'100%'} height={'100%'} />
            }
          </div>
        </Animated>
      </div>
        :
        sliders.filter((s, i) => i === 0).map((item, index) => {
          return <div className="info-container" key={index}>
            {isVisible && <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isVisible}>
              <div >
                {window.innerWidth > 1200 ? <MainPageProductSliderComponent slider={item} {...props} /> :
                  <MobileMainPageProductSliderComponent slider={item} {...props} />}
              </div>
            </Animated>}
          </div>
        })
      }
    </div >
    {<div className={"container"} style={{ maxHeight: 570, width: windowDimensions.width > 1200 ? 1200 : window.innerWidth, margin: '0px auto', paddingTop: window.innerWidth > 1200 ? 20 : -10, marginTop: window.innerWidth > 1200 ? 10 : 0, marginBottom: window.innerWidth > 1200 ? 200 : 0, minHeight: window.innerWidth > 1200 ? '504' : "0" }}>
      {<MainPageProductBanners {...props} />}
    </div>}

    <div className={"container"} style={{
      width: windowDimensions.width > 1200 ? window.innerWidth : '100%', margin: '0px auto',
      minHeight: window.innerWidth > 1200 ? 304 : 0,
      marginTop: window.innerWidth > 440 ? window.innerWidth > 600 ? window.innerWidth > 800 ? window.innerWidth > 1000 ? window.innerWidth > 1200 ? -200 : 220 : 170 : 120 : 70 : 125,
      marginBottom: width < 1200 ? '60px' : '0px'
    }}>
      {loadStatus ? <div className="info-container">
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
          <div >
            {window.innerWidth > 1200 ? <Skeleton width={1200} height={280} /> :
              <Skeleton width={'100%'} height={'100%'} />
            }
          </div>
        </Animated>
      </div> : sliders.filter((s, i) => i === 1).map((item, index) => {
        return <div className="info-container" key={index}>
          {isVisible && <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isVisible}>
            <div >
              {window.innerWidth > 1200 ? <MainPageProductSliderComponent slider={item} {...props} /> :
                <MobileMainPageProductSliderComponent slider={item} {...props} />}
            </div>
          </Animated>}
        </div >
      })}
    </div>
    {window.innerWidth > 1200 && <Box style={{ minHeight: 120, width: window.innerWidth }}></Box>}
    <div style={{ backgroundColor: COLORS.BEIGE, marginBottom: window.innerWidth > 1200 ? 0 : 60, minHeight: window.innerWidth > 1200 ? 504 : 0 }}>
      {<div style={{ width: 1200, margin: '0px auto', padding: window.innerWidth > 1200 ? 30 : 0, marginTop: 0 }}>
        {isVisible && <Animated animationIn="fadeInRight" animationOut="fadeOut" isVisible={isVisible}>
          <div style={{ marginBottom: 0 }}>
            <ProductSectionWithBG {...props} />
          </div>
        </Animated>}
      </div>}
    </div>
  </div>
}


const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    translations: state.translations,
    language: state.language
  }
}

export default connect(mapStateToProps, { register })(MainPage)