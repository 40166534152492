import { addressSerializer } from "../address/serializer"
import { cartItemSerializer } from "../cart/serializer"
import { paymentMethodSerializer } from "../payment/serializer"
import { Serializer } from "../serializer"
import { shippingMethodSerializer } from "../shipping/serializer"
import { Order } from "./Order"

export class OrderSerializer implements Serializer {
    fromJson(json: any) {
        const order = new Order()
        order.id = json.id
        if (json.shipping_method) {
            order.shippingMethod = shippingMethodSerializer.fromJson(json.shipping_method)
        }
        order.paymentMethod = paymentMethodSerializer.fromJson(json.payment_method)
        if (json.address) {
            order.address = addressSerializer.fromJson(json.address)
        }
        order.splitGiftCardPayment = json.split_gift_card_payment
        order.items = json.items.map(item => cartItemSerializer.fromJson(item))
        order.total = json.total
        order.created = json.created
        order.status = json.status
        order.totalToPay = json.total_to_pay
        order.shippingCost = json.shipping_cost
        return order
    }
    toJson(order: Order) {
        return {
            shipping_method: order.shippingMethod.id,
            payment_method: order.paymentMethod.id,
            lang: order.lang,
            loyalty_card: order.loyaltyCard,
            coupon: order.coupon,
            address: order.address.id
        }
    }
}
export class TBCInstallmentSerializer implements Serializer {
    fromJson(json: any) {
        const order = new Order()
        order.location = json.location
        return order
    }
    toJson(order: Order) {
        return {
            order_id: order.id
        }
    }
}
