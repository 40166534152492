import { Address } from "../address/address";
import { PaymentMethod } from "../payment/PaymentMethod";
import { Product } from "../product/product";
import { Resource } from "../resource";
import { ShippingMethod } from "../shipping/ShippingMethod";

export class Order extends Resource {
  created: string;
  location: string;
  items: Item[];
  coupon: string
  address: Address;
  paymentMethod: PaymentMethod;
  shippingCost: number;
  loyaltyCard: string;
  shippingMethod: ShippingMethod;
  status: string;
  total: number;
  totalToPay: number;
  lang: string;
  splitGiftCardPayment: boolean;
}

export class Item extends Resource {
  id: string;
  product: Product;
  quantity: number;
  sku: string;
}
